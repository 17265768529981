import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import AgeVerification from './components/AgeVerification';
import ManageEntries from './components/ManageEntries';
import ManageGames from './components/ManageGames';
import Login from './components/Login';
import Register from './components/Register';
import MainLayout from './components/MainLayout';
import BonusHuntTippspiel from './components/BonusHuntTippspiel';
import Dashboard from './components/Dashboard';
import SlotStats from './components/SlotStats';
import ClickStats from './components/ClickStats'; 
import { renewToken, isTokenExpired, getToken } from './utils/token';
import './App.css';
import StreamerView from './components/StreamerView';
import ViewerView from './components/ViewerView';
import ViewerViewActualDuel from './components/ViewerView_actualDuel';
import Giveaway from './components/Giveaway';
import StreamerView_Single from './components/StreamerView_Single';
import ViewerView_Single from './components/ViewerView_Single';
import ViewerViewActualDuel_Single from './components/ViewerView_actualDuel_Single';
import BossFightTeaser from './components/BossFight_Teaser';
import Bossfight from './components/StreamerView_Bossfight.js';
import Bossfight_Viewer from './components/ViewerView_Bossfight.js';
import Spinwheel from './components/Spinwheel.js';
import CommunityContact from './components/Community_Contact.js';


const PrivateRoute = ({ element }) => {
  const token = getToken();
  if (!token || isTokenExpired(token)) {
    return <Navigate to="/login" />;
  }

  const user = JSON.parse(atob(token.split('.')[1]));
  return user && user.role === 'admin' ? element : <Navigate to="/login" />;
};

function Home() {
  const handleVerification = (isOldEnough) => {
    if (isOldEnough) {
      window.location.href = "/casino-bonus";
    } else {
      alert("Come back when ur 18 or older =)");
    }
  };

  return <AgeVerification onVerify={handleVerification} />;
}

const AppContent = () => {
  const [bonusEntries, setBonusEntries] = useState([]);
  const [filters, setFilters] = useState({ filterBonusTypes: [], paymentMethods: [] });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`/bonusEntries.json?cache_bust=${new Date().getTime()}`);
      const data = await response.json();
      setBonusEntries(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const checkToken = async () => {
      await renewToken();
    };
    checkToken();
    const interval = setInterval(checkToken, 15 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const filteredEntries = bonusEntries.filter((entry) => {
    return (
      (filters.filterBonusTypes.length === 0 || (entry.filterBonusTypes && entry.filterBonusTypes.some(type => filters.filterBonusTypes.includes(type)))) &&
      (filters.paymentMethods.length === 0 || entry.extraInfo.paymentMethods.some((method) => filters.paymentMethods.includes(method.name)))
    );
  });

  return (
    <Routes>
      <Route path="/" element={<MainLayout><Home /></MainLayout>} />
      <Route path="/casino-bonus" element={
        <MainLayout filters={filters} setFilters={setFilters}>
          <MainContent bonusEntries={filteredEntries} filters={filters} setFilters={setFilters} />
        </MainLayout>
      } />
      <Route path="/bonus-hunt-tippspiel" element={
        <MainLayout filters={filters} setFilters={setFilters}>
          <BonusHuntTippspiel />
        </MainLayout>
      } />
      <Route path="/giveaway" element={
        <MainLayout filters={filters} setFilters={setFilters}>
          <Giveaway />
        </MainLayout>
      } />
            <Route path="/community" element={
        <MainLayout filters={filters} setFilters={setFilters}>
          <CommunityContact />
        </MainLayout>
      } />
            <Route path="/bossfight-teaser" element={
        <MainLayout filters={filters} setFilters={setFilters}>
          <BossFightTeaser />
        </MainLayout>
      } />
      <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />}>
        <Route path="manage-games" element={<ManageGames />} />
        <Route path="manage-entries" element={<ManageEntries />} />
        <Route path="click-stats" element={<ClickStats />} />
        <Route path="streamer" element={<StreamerView />} />
        <Route path="streamer_single" element={<StreamerView_Single />} />
        <Route path="bossfight" element={<Bossfight />} /> 
        
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/slotstats" element={<SlotStats />} />
      <Route path="/viewer" element={<ViewerView />} />
      <Route path="/viewer-actual-duel" element={<ViewerViewActualDuel />} />
      <Route path="/viewer-actual-duel_single" element={<ViewerViewActualDuel_Single />} />
      <Route path="/viewer_single" element={<ViewerView_Single />} />
      <Route path="/spinwheel" element={<Spinwheel />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/bossfight_viewer" element={<Bossfight_Viewer />} /> 
    </Routes>
  );
};

function App() {
  const location = useLocation();
  const isDashboard = location.pathname.startsWith('/dashboard');

  return (
    <div className="App">
      <div className="content">
        <AppContent />
      </div>
      {!isDashboard && <Footer />}
    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
